import React from 'react';
import { Link } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
} from 'reactstrap';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment';
import 'moment/locale/pt-br';

import Utils from 'utils/Utils';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import EventoModal from 'views/components/EventoModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import EventosService from 'services/EventosService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Papel from 'assets/csv/papeis.json';
import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class AgendaDiario extends React.Component {
  constructor(props) {
    super(props);

    this.messages = {
      allDay: 'Dia Inteiro',
      previous: '<',
      next: '>',
      today: 'Hoje',
      month: 'Mês',
      week: 'Semana',
      day: 'Dia',
      agenda: 'Agenda',
      date: 'Data',
      time: 'Hora',
      event: 'Evento',
      showMore: (total) => `+ (${total}) Eventos`,
    }

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      error: null,

      eventoSelecionado: {
        titulo: '',
      }
    };

    this.localizer = momentLocalizer(moment)

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.eventosService = new EventosService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      await this.carregarEventosDoDiario();

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarEventosDoDiario() {
    
    const eventos = await this.eventosService
      .carregarEventosDoDiario(this.props.match.params.idDiario);

    eventos.forEach((evento) => Object.assign(evento, {
      title: evento.titulo,
      allDay: evento.diaInteiro,
      start: moment(evento.inicio, 'DD/MM/YYYY HH:mm:ss').toDate(),
      end: moment(evento.fim, 'DD/MM/YYYY HH:mm:ss').toDate(),
      hexColor: Utils.getCorEvento(evento.tipo),
    }));

    this.setState({ eventos });
  }

  eventStyleGetter(event, start, end, isSelected) {
    var backgroundColor = event.hexColor;
    var style = {
        backgroundColor: backgroundColor,
        color: 'black',
    };
    return {
        style: style
    };
  }

  abrirModalEvento(evento) {

    const eventoSelecionado = {
      id: evento.id,
      titulo: evento.titulo || '',
      tipo: evento.tipo || evento.tipo === 0 ? evento.tipo : '',
      descricao: evento.descricao || '',
      diaInteiro: evento.diaInteiro || true,
      inicio: evento.start || new Date(),
      fim: evento.end || new Date(),
    };

    this.setState({
      showEventoModal: true,
      eventoSelecionado,
    })
  }

  async cadastrarEvento(evento) {
    this.setState({
      showEventoModal: false,
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando evento...',
    });

    try {
      await this.eventosService.cadastrarEvento(this.props.match.params.idDiario, evento);

      await this.carregarEventosDoDiario();
      
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: `Evento ${!evento.id ? 'cadastrado' : 'atualizado'} com sucesso`,
      });
    } catch(e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || `Erro ao ${!evento.id ? 'cadastrar' : 'atualizar'} evento`,
      });
    }
  }

  confirmarRemocaoEvento(idEvento) {
    this.setState({ 
      idEventoSelecionado: idEvento, 
      showEventoModal: false,
      showConfirmModal: true,
    });
  }

  async removerEvento() {
    this.setState({ 
      showAlert: false, 
      showLoaderModal: true,
      loaderModalText: 'Excluindo evento...'
    });

    try {
      await this.eventosService
        .removerEvento(this.state.idEventoSelecionado);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Evento removido com sucesso',
      });

      this.carregarEventosDoDiario();
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover evento',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Agenda</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Agenda
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Calendar
                selectable
                localizer={this.localizer}
                events={this.state.eventos}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 800 }}
                onSelectEvent={(event) => this.abrirModalEvento(event)}
                onSelectSlot={(event) => this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado ? this.abrirModalEvento(event) : undefined}
                eventPropGetter={(this.eventStyleGetter)}
                messages={this.messages}
              />
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ 
              showConfirmModal: false
            });
            if (confirm) {
              this.removerEvento();
            } else {
              this.setState({ idEventoSelecionado: null });
            }
          }}
          text="Confirme a exclusão do evento." />
        <EventoModal
          onlyRead={this.props.role !== Papel.PROFESSOR.value}
          isOpen={this.state.showEventoModal}
          evento={this.state.eventoSelecionado}
          callbackCadastrar={(evento) => this.cadastrarEvento(evento)}
          callbackExcluir={(idEvento) => this.confirmarRemocaoEvento(idEvento)}/>
      </div>
    );
  }
}


export default AgendaDiario;
